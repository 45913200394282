@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
.item-date {
  text-align: left;
}
.total-user {
  width: 444px;
  height: 24px;
  background: #fae1ee;
  border-radius: 4px;
  border: 1px solid #f394ac;
}
